/* eslint-disable no-undef */
import {defineStore} from "pinia";
import axios from "axios";
import i18n from "@/locales/i18n";
import {useLocalStorage} from "@vueuse/core"
import {useToast} from "vue-toastification";
import APIRequest from "@/libs/cloud_auth";

const languages = [
    {code: "EN", name: "English"},
    {code: "PT", name: "Portuguese"},
    {code: "FR", name: "French"},
    {code: "DE", name: "Deutsch"},
    {code: "ES", name: "Spanish"},
    {code: "NL", name: "Dutch"},
]

export const useAppStore = defineStore("app", {
    state: () => ({
        appLoading: false,
        appLanguage: useLocalStorage('lang', 'EN'),
        sidebar: useLocalStorage('sidebar_expanded', true),
        tenant: localStorage.getItem('tenant') ? JSON.parse(localStorage.getItem('tenant')) : null,
        hotels: useLocalStorage('hotels', Array),
        cities: useLocalStorage('cities', Array),
        airports: useLocalStorage('airports', Array),
        session: useLocalStorage('session', Array),
        settings: {
            date_format: "DD/MM/YYYY",
            timezone: "Europe/Lisbon",
            language: "EN",
            location: "Porto"
        },
        menu: [
            {
                title: i18n.global.t('menu.players'),
                icon: 'Icon_players',
                link: 'players',
                roles: ['client-admin', 'nonius-admin', 'client-sales-manager', 'nonius-technical', 'nonius-engineer'],
            },
            {
                title: i18n.global.t('menu.presentations'),
                icon: 'Icon_presentations',
                link: 'presentations',
                roles: ['client-admin', 'nonius-admin', 'client-brand-manager', 'client-events-manager', 'client-sales-manager'],
        },
            {
                title: i18n.global.t('menu.media'),
                icon: 'Icon_media',
                roles: ['client-admin', 'nonius-admin', 'client-brand-manager', 'client-events-manager', 'client-sales-manager'],
                submenu: [
                    {
                        title: i18n.global.t('menu.gallery'),
                        link: 'gallery',
                        roles: ['client-admin', 'nonius-admin', 'client-brand-manager', 'client-events-manager', 'client-sales-manager'],
                    },
                    // {
                    //     title: i18n.global.t('menu.playlists'),
                    //     link: 'playlists',
                    //     roles: ['client-admin', 'nonius-admin', 'client-brand-manager', 'client-events-manager', 'client-sales-manager'],
                    // }
                ]
            },
            {
                title: i18n.global.t('menu.conferences'),
                icon: 'Icon_conferences',
                link: 'conferences',
                roles: ['client-admin', 'nonius-admin', 'client-events-manager', 'client-sales-manager'],
            },
            {
                title: i18n.global.t('menu.campaign'),
                icon: 'Icon_scheduler',
                link: 'scheduler',
                roles: ['client-admin', 'nonius-admin', 'client-events-manager', 'client-sales-manager'],
            },
            {
                title: i18n.global.t('menu.templates'),
                icon: 'Icon_groups',
                link: 'templates',
                roles: ['client-admin', 'nonius-admin', 'client-brand-manager'],
            },
            {
                title: i18n.global.t('menu.diagnostics'),
                icon: 'Icon_diagnostics',
                roles: ['client-admin', 'nonius-admin', 'nonius-engineer'],
                submenu: [
                    {
                        title: i18n.global.t('menu.troubleshooting'),
                        link: 'troubleshooting',
                        roles: ['client-admin', 'nonius-admin', 'nonius-engineer'],
                    }
                ]
            },
            {
                title: i18n.global.t('menu.settings'),
                icon: 'Icon_settings',
                link: 'settings',
                roles: ['client-admin', 'nonius-admin', 'client-brand-manager', 'client-sales-manager', 'nonius-technical', 'nonius-engineer'],
            },
             {
                 title: i18n.global.t('menu.softwareUpdate'),
                 icon: 'Icon_software',
                 roles: ['nonius-admin', 'nonius-technical', 'nonius-engineer'],
                 submenu: [
                     {
                         title: i18n.global.t('menu.management'),
                         link: 'management',
                         roles: ['nonius-admin', 'nonius-technical', 'nonius-engineer'],
                     },
                     {
                         title: i18n.global.t('menu.deviceList'),
                         link: 'devices',
                         roles: ['nonius-admin', 'nonius-technical', 'nonius-engineer'],
                     }
                 ]
             },
        ],
        appLanguages: languages,
    }),
    getters: {
        getCurrentRole() {
            return this.tenant.role;
        },
        getCurrentTenant() {
            return this.tenant.ncm;
        },
        getTenantGroup() {
            return this.tenant.group_id;
        },
        getHotels(){
            return this.hotels.map((item) => {
                return { value: item.ncm, name: item.name };
            });
        },
        getListedHotels(){
            return this.hotels.map((item) => {
                return item.ncm;
            });
        },
        isNoniusAdmin() {
            return this.getCurrentRole.startsWith("nonius-");
        },
        getGroupHotels() {
            const groupId = this.getTenantGroup;
            if (!groupId) return [];

            const groupHotels = this.hotels
                .filter(hotel => hotel.group_id === groupId)
                .map(hotel => ({ value: String(hotel.ncm), name: hotel.name }));

            return groupHotels;
        }
    },
    actions: {
        async isAlive() {
            try {
                await axios.get(`alive/`);
                return true;
            } catch (error) {
                return false;
            }
        },
        async logDataUsage(logs){
            try {
                const index = "signage-backoffice-*";
                const username = process.env.VUE_APP_ELASTIC_USERNAME;
                const password = process.env.VUE_APP_ELASTIC_PASSWORD;
                const authHeaders = btoa(username + ":" + password);

                let bulkData = [];
                if(!logs || !logs.length) return;

                for (var i = 0; i < logs.length; i++) {
                    bulkData.push(JSON.stringify({ create: { _index: index } }));
                    bulkData.push(JSON.stringify(logs[i]));
                }	

                const bulkPayload = bulkData.join('\n') + '\n';

                await axios.post(
                    `https://${process.env.VUE_APP_ELASTIC_IP}/_bulk`,
                    bulkPayload,
                    {
                    headers: {
                        'Content-Type': 'application/x-ndjson',
                        'Authorization': `Basic ${authHeaders}`
                    },
                })
                .catch((error) => {
                    console.log(error);
                    return false
                });

                return true;
            } catch (error) {
                console.log(error)
                return false;
            }
        },
        async getSettings() {
            try {
                const response = await APIRequest(`properties/settings/?tenant__ncm_id=` + this.getCurrentTenant);
                const result = response.data.results;
                if(!result || !result.length) return;
                
                this.settings = {
                    date_format: result[0].date_format.toUpperCase(),
                    timezone: result[0].timezone,
                    language: result[0].language,
                    location: result[0].location
                }

            } catch (error) {
                console.log(error);
            }
          },
        async fetchCityAndAirportData() {
            try {
              const [cityResponse, airportResponse] = await Promise.all([
                  axios.get(`/cityList/`),
                  axios.get(`/airports/`)
              ]);
              
              this.cities = cityResponse.data;
              this.airports = airportResponse.data;
            
            } catch (error) {
                console.log(error);
            }
          },
        async getCities(){
            try {
                const response = await axios.get(`cityList/`)
                return response.data
            } catch(error) {
                console.log(error)
            }
        },
        async authenticateUser(user) {
            try {
                if(this.session?.user_impersonation) return true;
                const aclObject = user[process.env.VUE_APP_IDENTITY_ACL_URL][process.env.VUE_APP_IDENTITY_MODE];
                await APIRequest('auth/cloud_login/', 'post',
                {
                    username: user.email,
                    url: aclObject,
                })
                
                return true;

            } catch (error) {
                console.log(error);
            }
            return false;
        },
        async getUserSession(){
            try {
                const response = await APIRequest('auth/session/');
                this.session = response.data;
                return response.data
            } catch(error) {
                console.log(error)
            }
        },
        async getTenantsList() {
            try {
                const session = await this.getUserSession();
                const tenants = session.tenants;

                // TESTING PURPOSES: CREATING FAKE ADMIN
                // ====== REMOVE ON PRODUCTION =======
                 if(process.env.VUE_APP_TITLE === 'Nonius Signage Local'){
                    tenants.push({
                        code: "NCM666",
                        name: "DEV TENANT",
                        role: "nonius-admin"
                    })

                    tenants.push({
                        code: "aaaaaaaaaaaaa",
                        name: "AAAAAAAAAAAAA",
                        role: "client-admin"
                    })

                    tenants.push({
                        code: "NCMEVENTMANAGER",
                        name: "Event Manager",
                        role: "client-events-manager"
                    })

                    tenants.push({
                        code: "NCMSALESMANAGER",
                        name: "Sales Manager",
                        role: "client-sales-manager"
                    })
                }

                let hotelList = [];
                tenants.forEach((tenant) => {
                    const { name, code, role } = tenant;
                    if (!hotelList.some(e => e.code === code && e.name === name && e.role === role)) {
                        hotelList.push({ role, ncm: code, name });
                    }
                });

                if (!hotelList.length) {
                    useToast().error("This user doesn't have any tenants.");
                    return false;
                }

                hotelList.sort((a, b) => {
                    return a.name.localeCompare(b.name)
                })

                this.hotels = hotelList
                return true;

            } catch (error) {
                console.log(error)
                return false;
            }

        },
        async setupGroups(){
            try {
                const result = new Map(await APIRequest(`properties/linked-tenant/`)
                .then(response => response.data.map(item => [item.ncm, item.group])));

                this.hotels.forEach(hotel => {
                    hotel.group_id = result.get(hotel.ncm.toString()) || "";
                });
                return true;
            } catch (error) {
                return false;
            }
        },
        async setupDB(user) {
            const response = await APIRequest(`properties/user/?email=${user.email}`)
            if (!response.data.results.length) {
                await APIRequest(`properties/user/`, 'post', {
                    "name": user.name,
                    "email": user.email
                })
            }
            return response.data
        },
        async setupTenantList() {
            try {
                const hotelList = this.hotels;

                // Fetch existing tenants with those NCMs.
                const ncmString = hotelList.map(hotel => hotel.ncm);
                const ncmObject = {
                    ids: ncmString
                };
                const response = await APIRequest('properties/tenant/get_by_ncm/', 'post', ncmObject)                
                const existingNCMs = new Set(response.data.map(tenant => tenant.ncm_id));

                // Filter new tenants that don't exist
                const newTenantsBatch = hotelList.reduce((batch, hotel) => {
                    if (!existingNCMs.has(String(hotel.ncm))) {
                        batch.push({ ncm_id: hotel.ncm, name: hotel.name });
                    }
                    return batch;
                }, []);
                
                // If there are new tenants to be added, batch insert them.
                if (newTenantsBatch.length) {
                    await APIRequest(`properties/tenant/`, 'post', newTenantsBatch);
                }

                return true;
            } catch (error) {
                console.error('Error in setupTenantList:', error);
                throw error;
            }
        },
        async setupAdmins(tenants) {
            try {
                const ncmList = tenants.map(tenant => String(tenant.ncm));
                const ncmObject = {
                    ids: ncmList
                };

                const response = await APIRequest('properties/linked-tenant/get_by_ncm/', 'post', ncmObject);
                let groupId = null;

                // Check if there are existing linked tenants with a group
                if (response.data.length) {
                    const found = response.data.find(result => result.group);
                    groupId = found.group;
                } else {
                    // If no linked tenants exist, create a new group
                    const newGroup = await APIRequest(`properties/group/`, 'post', {"name": 'Unnamed Group'});
                    groupId = newGroup.data.id;
                }

                // Filter out NCMs that already have a linked tenant
                const ncmToLink = ncmList.filter(ncm => !response.data.some(r => r.ncm === String(ncm)));

                // Prepare the data for batch creation of linked tenants
                const linkedTenantsBatch = ncmToLink.map(ncm => ({
                    "ncm": ncm,
                    "group": groupId
                }));

                // Batch create linked tenants
                if (linkedTenantsBatch.length) {
                    await APIRequest(`properties/linked-tenant/`, 'post', linkedTenantsBatch);
                }
            } catch (err) {
                console.error(err);
            }
        },
        getHotelName(ncm) {
            return this.hotels.find((x) => x.ncm === ncm)?.name
        },
        getBucket(group) {
            if (!group)
                return this.tenant.ncm;
            else
                return this.tenant.group_id;

        },
        async setTenant(val) {
            this.tenant = await this.hotels.find(hotel => hotel.ncm === val.ncm) || null;
            localStorage.setItem("tenant", JSON.stringify(this.tenant));
        },
        async setTenantSelect(val) {
            this.tenant = await this.hotels.find(hotel => hotel.ncm === val) || null;
            localStorage.setItem("tenant", JSON.stringify(this.tenant));
        },
        toggleSidebar() {
            this.sidebar = !this.sidebar
        },
        getFirstRoute() {
            if (!this.tenant || !this.menu.find(item => item.roles.includes(this.tenant.role))) return '403'
            return this.menu.find(item => item.roles.includes(this.tenant.role)).link;
        },


        // IMPERSONATOR
        async getCloudUsers() {
            try {
                const response = await APIRequest('auth/cloud_users/');
                return response.data;

            } catch(error) {
                console.log(error)
            }  
        },
        async impersonateLogin(uuid, username, name) {
            this.appLoading = true;
            const response = await APIRequest('auth/impersonate_login/', 'post', {
                uuid,
                username,
                name
            });

            if(response.data["error"]){
                this.appLoading = false;
                return useToast().error(this.$t('toast.impersonateFailed'));
            }

            try {
                await this.getTenantsList();
                await this.setTenant(this.hotels[0])
                return true;
            } catch(error) {
                console.log(error)
                this.appLoading = false;
            }
            return false;
        },
        async impersonateLogout(email) {
            try {
                this.appLoading = true;
                await APIRequest('auth/impersonate_logout/', 'post', {
                    original_user_id: email
                });
                await this.getTenantsList();
                await this.setTenant(this.hotels[0])
                return true;
            } catch(error) {
                console.log(error)
                this.appLoading = false;
            }
            return false;
        },
    },
});
